import Vuex from 'vuex'
import constants from '@/utils/constants'
import AudioUtil from '@/utils/audioUtil'
import hint from '@/components/Hint'
import '@/assets/styles/courses/dialogueModel.scss'
import '@/assets/styles/courses/grammarTips.scss'

const dialogueMixins = {
  data() {
    return {
      underline: constants.underline,
      words: [],
      wordsNum: 0,
      activeIndex: -1,
      wordTransVisible: {}
    }
  },
  props: ['dialogueData', 'audioIndex', 'audio'],
  components: {
    hint
  },
  computed: {
    ...Vuex.mapGetters(['learnLanguage'])
  },
  created() {
    this.initData()
  },
  watch: {
    'audio.playState': async function(val) {
      if (val === 'start' && this.audioIndex === this.audio.index) {
        this.$refs.dialogueAudio.classList.remove('lock')
        this.$refs.dialogueAudio.classList.add('active')
        let duration = this.audio.duration(this.audioIndex)
        let sentence = this.dialogueData.st
        let oneCharAudioDuration = duration / sentence.length
        for (let word of this.words) {
          await new Promise(res => {
            let oneWordAudioDuration = word.wd.length * oneCharAudioDuration * 1000
            setTimeout(res, oneWordAudioDuration)
            this.$set(word, 'highlight', true)
          })
        }
      } else {
        this.$refs?.dialogueAudio?.classList?.remove('active')
        for (let word of this.words) {
          this.$set(word, 'highlight', false)
        }
      }
    }
  },
  methods: {
    initData() {
      let { wls } = this.dialogueData
      let words = this.prefixFilter(wls)
      for (let i = 0; i < words.length; i ++) {
        let word = words[i]
        let lastWord = words[i - 1]
        this.wordTransVisible[i] = false

        if (i === 0 || ['.', '!', '¡', '?', '¿'].includes(lastWord.wd)) {
          word.wd = this.capitalize(word.wd)
        }
      }
      this.words = words
      this.wordsNum = words.filter(word => word.ty !== '1').length
    },
    // 过滤前缀单词
    prefixFilter(arr) {
      return arr.filter(item => !constants.coursesDialoguePrefix.includes(item.wd))
    },
    // 首字母大写
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    playAudioHandle() {
      if (this.correct) {
        this.audio.play(this.audioIndex)
      }
    },
    playAudio(type, id) {
      let audioUrl =
          constants.courseMediaUrl.replace('LANG', this.learnLanguage) +
          'lesson_f/' +
          constants.langsData[this.learnLanguage]['courseMediaName'] +
          `-f-${type}-${id}.mp3`
      let audio = new AudioUtil([{ audioUrl }])
      audio.play()
    },
    showWordTransHandle(word, index, showFlag) {
      if (showFlag) {
        this.$emit('clearPopoverAll')
        this.activeIndex = index
        this.wordTransVisible[index] = true
        this.playAudio('w', word.id)
      }
    },
    clearPopover() {
      this.activeIndex = -1
      for (let index in this.wordTransVisible) {
        this.wordTransVisible[index] = false
      }
    }
  }
}

export default dialogueMixins